import React, { useEffect } from "react"
import { Link, graphql, navigate } from "gatsby"
import { motion } from "framer-motion"

import Footer from "../components/footer"
import NavigationSpacer from "../components/navigation-spacer"
import Seo from "../components/seo"

const variants = {
    paragraph: {
        color: "var(--color-primary)",
        display: "block",
        fontSize: "1em",
        marginTop: "0.67em",
        marginLeft: 0,
        marginRight: 0,
        visibility: "visible",
        position: "absolute"
    },
    header: {
        color: "var(--color-title)",
        display: "block",
        fontSize: "2em",
        marginTop: "0.67em",
        marginLeft: 0,
        marginRight: 0,
        fontWeight: "bold",
        visibility: "visible",
        position: "absolute",
        top: 0
    },
    gone: {
        visibility: "hidden"
    }
}

const PostsIndex = ({ data, location }) => {
    const posts = data.allMdx.nodes
    const [indexClicked, setIndexClicked] = React.useState(null)

    if (posts.length === 0) {
        return (
            <div className="main-page h-100">
            </div>
        )
    }

    return (
        <div className="main-page h-100">
            <Seo title="Posts" />
            <div className="container-fluid d-flex flex-column main-mh-100">
                <NavigationSpacer />
                <div className="container flex-grow-1">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-8 pt-5">
                            <ol style={{ listStyle: "none", padding: "0", position: "relative" }}>
                                {posts.map(post => {
                                    const title = post.frontmatter.title || post.fields.slug

                                    return (
                                        <li key={post.fields.slug.toString()}>
                                            <article
                                                className="post-list-item"
                                                itemScope
                                                itemType="http://schema.org/Article"
                                            >
                                                <header>
                                                    <h1 data-slug={post.fields.slug.toString()} style={{color: "var(--color-primary)", display: "block", fontSize: "1em", marginBottom: "8px", marginLeft: "0px", marginRight: "0px", visibility: "visible"}}>
                                                        <strong className="main-reveal-text-short d-block">
                                                            <a itemprop="url" href={post.fields.slug.toString()}>
                                                                <span itemprop="headline">{title}</span>
                                                            </a>
                                                        </strong>
                                                    </h1>
                                                </header>
                                                <motion.section
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    transition={{ ease: "circOut", duration: (indexClicked === post.fields.slug ? 0.2 : 0.0) }}
                                                >
                                                    <div className="main-fade-in-animation mb-5">
                                                        <small>{post.frontmatter.date} &#8226; {post.timeToRead + 3} min read</small>
                                                        <p itemProp="description">{post.frontmatter.description || post.excerpt}</p>
                                                    </div>
                                                </motion.section>
                                            </article>
                                        </li>
                                    )
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
                <motion.span
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: "circOut", duration: (indexClicked != null ? 0.2 : 0.0) }}
                    style={{ marginTop: "1em" }}
                >
                    <Footer />
                </motion.span>
            </div>
        </div>
    )
}

export default PostsIndex

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                timeToRead
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "YYYY-MM-DD")
                    title
                    description
                    image
                }
            }
        }
    }
`